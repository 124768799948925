import {
	Create,
	Datagrid,
	Edit,
	EditButton,
	required,
	List,
	SimpleForm,
	DateField,
	SimpleList,
	TextField,
	TextInput,
	useTranslate,
	Filter,
	DeleteButton,
	ReferenceInput,
	AutocompleteInput,
	PasswordInput,
} from 'react-admin';

import { CreateToolbar, TopBarActions } from '../../components';

const DeliverymanFilter = (props) => {
	const translate = useTranslate();
	return (
		<Filter {...props}>
			<TextInput label={translate('deliveryman.name')} source='name' alwaysOn />
			<TextInput
				label={translate('deliveryman.mobile')}
				source='mobile'
				alwaysOn
			/>
		</Filter>
	);
};

export const DeliverymanList = (props) => {
	const isSmall = false;

	const translate = useTranslate();
	return (
		<List
			{...props}
			title={translate('deliveryman.title')}
			filters={<DeliverymanFilter />}
			className='title'
			sort={{ field: 'id', order: 'DESC' }}
		>
			{isSmall ? (
				<SimpleList
					primaryText={() => translate('deliveryman.deliveryman')}
					secondaryText={(record) =>
						`${record.zh_name} ${record.ug_name}  ${record.mobile}`
					}
				/>
			) : (
				<Datagrid>
					<TextField
						label={translate('deliveryman.mobile')}
						source='mobile'
						sortByOrder='DESC'
					/>
					<TextField label={translate('deliveryman.name')} source='ug_name' />
					<TextField label={translate('deliveryman.name')} source='zh_name' />
					<DateField
						source='created_at'
						label={translate('orders.paidAt')}
						showTime
						sortByOrder='DESC'
					/>
					<EditButton />
					<DeleteButton />
				</Datagrid>
			)}
		</List>
	);
};
const stationLabel = (record) => `${record.zh_name} ${record.ug_name}`;
export const DeliverymanEdit = (props) => {
	const translate = useTranslate();
	return (
		<Edit
			{...props}
			title={translate('deliveryman.edit')}
			actions={<TopBarActions />}
		>
			<SimpleForm>
				<ReferenceInput
					validate={required()}
					reference='station'
					source='station_id'
					label={translate('station.name')}
				>
					<AutocompleteInput
						label={translate('station.name')}
						optionText={stationLabel}
						optionValue='id'
					/>
				</ReferenceInput>
				<PasswordInput
					source='password'
					label={translate('accounts.password')}
				/>
				<TextInput
					source='mobile'
					label={translate('deliveryman.mobile')}
					validate={required()}
				/>
				<TextInput
					source='ug_name'
					label={translate('deliveryman.ug_name')}
					validate={required()}
				/>
				<TextInput
					source='zh_name'
					label={translate('deliveryman.zh_name')}
					validate={required()}
				/>
			</SimpleForm>
		</Edit>
	);
};

export const DeliverymanCreate = (props) => {
	const translate = useTranslate();

	return (
		<Create
			{...props}
			title={translate('deliveryman.new')}
			actions={<TopBarActions />}
		>
			<SimpleForm toolbar={<CreateToolbar />}>
				<ReferenceInput
					validate={required()}
					reference='station'
					source='station_id'
					label={translate('station.name')}
				>
					<AutocompleteInput
						label={translate('station.name')}
						optionText={stationLabel}
						optionValue='id'
					/>
				</ReferenceInput>
				<TextInput
					source='ug_name'
					label={translate('deliveryman.ug_name')}
					validate={required()}
				/>
				<PasswordInput
					source='password'
					label={translate('accounts.password')}
				/>
				<TextInput
					source='zh_name'
					label={translate('deliveryman.zh_name')}
					validate={required()}
				/>
				<TextInput
					source='mobile'
					label={translate('deliveryman.mobile')}
					validate={required()}
				/>
			</SimpleForm>
		</Create>
	);
};
