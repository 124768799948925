import React from 'react';

import { Marker } from '@pansy/react-amap';
const DeliverymanMarkers = ({ deliverymen, selectDeliveryman }) => {
	const mapDeliverymanEvents = {
		click: (event) => {
			selectDeliveryman(event.target._opts.extData);
		},
	};
	if (!deliverymen) {
		return null;
	}
	return (
		<>
			{deliverymen.map((item, index) => {
				if (!item.position) {
					return null;
				}
				return (
					<Marker
						className='marker'
						clickable
						key={index}
						position={item.position}
						anchor={[0, 0]}
						size={[48, 48]}
						extData={item}
						label={item.zh_name}
						events={mapDeliverymanEvents}
						// icon={item.bigIcon}
					>
						<div
							className='name'
							style={{
								backgroundColor: 'rgba(200, 200, 200, 0.8)',
								border: '1px solid #eee',
								fontSize: '11px',
								width: '150px',
								textAlign: 'center',
								padding: '0px',
							}}
						>
							{item.zh_name}-{item.mobile}
						</div>
						<div className='image'>
							<img
								src='https://bulutbazar.com/assets/front/images/deliveryman.png'
								width='48'
								alt={item.zh_name}
							/>
						</div>
					</Marker>
				);
			})}
		</>
	);
};

export default DeliverymanMarkers;
