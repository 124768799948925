import React, { useEffect, useState } from 'react';
import moment from 'dayjs';
import { timeToMinute } from '../../helpers';

const OrderTime = ({ order }) => {
	const [orderTime, setOrderTime] = useState(0);
	useEffect(() => {
		const interval = setInterval(() => {
			setOrderTime(orderTime + 1);
		}, 1000);
		return () => clearInterval(interval);
	}, [orderTime]);

	if (
		!(
			order.status === 1 ||
			order.status === 2 ||
			order.status === 9 ||
			order.status === 10 ||
			order.status === 12 ||
			order.status === 15 ||
			order.status === 17
		)
	) {
		return null;
	}
	return (
		<div>{timeToMinute(moment().diff(moment(order.payed_at), 'seconds'))}</div>
	);
};

export default OrderTime;
