import {
	ListButton,
	SaveButton,
	Toolbar,
	TopToolbar,
	useRecordContext,
	useTranslate,
} from 'react-admin';

import Pagination from '@mui/material/Pagination';

import { Avatar } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const TopBarActions = ({ basePath }) => {
	const translate = useTranslate();
	return (
		<TopToolbar>
			<ListButton basePath={basePath} label={translate('common.backToList')} />
		</TopToolbar>
	);
};

export const CreateToolbar = (props) => {
	const translate = useTranslate();
	return (
		<Toolbar {...props}>
			<SaveButton
				label={translate('common.saveAndShow')}
				redirect='list'
				submitOnEnter={true}
			/>
			<SaveButton
				label={translate('common.saveAndAdd')}
				redirect={false}
				submitOnEnter={false}
				variant='text'
			/>
		</Toolbar>
	);
};

const AvatarField = ({ record, size = '25', className }) =>
	record ? (
		<Avatar
			src={`${record.avatar}?size=${size}x${size}`}
			style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
			className={className}
		/>
	) : null;

export const FullNameField = ({ record, size }) => {
	return record ? (
		<div>
			<AvatarField className='avatar' record={record} size={size} />
			{record.name ?? record.nickname}
		</div>
	) : null;
};

export const UsersGender = ({ source }) => {
	const record = useRecordContext();
	let gender = '';
	if (record) {
		switch (record.gender) {
			case '0':
				gender = '未指定';
				break;
			case '1':
				gender = '男';
				break;
			case '2':
				gender = '女';
				break;
			default:
				break;
		}
	}
	return gender;
};

export const imageInputFormat = (file) => {
	if (file && file.rawFile instanceof File) {
		return file;
	}
	if (file && Array.isArray(file)) {
		const arr = [];
		file.forEach((i) => {
			if (i && i.rawFile instanceof File) {
				arr.push({ src: i });
			} else if (i.url) {
				arr.push({ src: i.url });
			} else {
				arr.push(i);
			}
		});
		return arr;
	}
	if(file && file.src){
		return file;
	}
	return { src: file };
};

export const CustomPagination = ({
	total,
	rowsPerPage,
	page,
	handleChangePage,
}) => (
	<Pagination
		count={Math.ceil(total / rowsPerPage)}
		page={page}
		onChange={handleChangePage}
	/>
);

export const HtmlTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
	},
}));
