import polyglotI18nProvider from "ra-i18n-polyglot";
import { zh, ug } from "./i18n/index";

const messages = {
  zh: zh,
  ug: ug,
};

let lang = localStorage.getItem("lang");
lang = lang === "ug" ? "ug" : "zh";
const i18nProvider = polyglotI18nProvider(
  (locale) => (locale === "ug" ? messages.ug : messages.zh),
  lang
);

export default i18nProvider;
