import React, { useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete } from '@mui/lab';
import { Box, Chip, Divider } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { buildBaseURL, httpClient } from '../../http';
import OrderInfoForDialog from '../orders/OrderInfoForDialog';

const ReDistributeToDeliveryman = ({
	openOrderDistribute,
	setOpenOrderDistribute,
	selectedOrder,
	selectOrder,
	deliverymen,
}) => {
	const [deliveryman, setDeliveryman] = useState();
	const [submitting, setSubmitting] = useState(false);
	const notify = useNotify();
	const translate = useTranslate();

	const changeDeliveryman = async () => {
		if (!deliveryman) {
			notify(translate('distribute.chooseDeliveryman'), { type: 'error' });
			return;
		}
		setSubmitting(true);

		try {
			const url =
				buildBaseURL('v1', 'takeout') +
				`agent/distribute/order/${selectedOrder.id}/to/${deliveryman.id}`;
			const res = await httpClient(url, {
				method: 'POST',
			});
			if (res.status === 200) {
				if (!res.data.result) {
					notify(res.data.message, { type: 'error' });
				} else {
					notify(res.data.message, { type: 'success' });
					handleCloseDialog();
				}
				setSubmitting(false);
				return;
			} else {
				notify(res.data.message, { type: 'error' });
				setSubmitting(false);
				return;
			}
		} catch (e) {
			setSubmitting(false);
			notify(e.message, { type: 'error' });
			console.log(
				'🚀 ~ file: MapDistribute.jsx ~ line 130 ~ changeDeliveryman ~ e',
				e
			);
		}
	};

	const handleCloseDialog = () => {
		setOpenOrderDistribute(false);
		selectOrder(false);
	};

	const BackDropForDialog = () => {
		return (
			<Backdrop
				sx={{ color: '#fff', zIndex: 1400 }}
				open={submitting}
				onClick={() => {
					setSubmitting(false);
				}}
			>
				<CircularProgress sx={{ zIndex: 1401 }} color='inherit' />
			</Backdrop>
		);
	};
	return (
		<>
			<Dialog open={openOrderDistribute} onClose={handleCloseDialog}>
				<DialogTitle>
					{translate('orders.orderNoLabel', {
						orderNo: selectedOrder?.order_no,
					})}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<OrderInfoForDialog order={selectedOrder} />
					</DialogContentText>
					<Box sx={{ bgcolor: '#fff', height: '40px', width: '100%' }} />
					<Divider>
						<Chip label={translate('distribute.reDistribute')} />
					</Divider>
					<Autocomplete
						getOptionLabel={(option) => option.zh_name}
						onChange={(event, newValue) => {
							setDeliveryman(newValue);
						}}
						disablePortal
						id='combo-box-demo'
						options={deliverymen}
						sx={{ width: 400, height: 200 }}
						renderInput={(params) => (
							<TextField
								{...params}
								label={translate('deliveryman.deliveryman')}
							/>
						)}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDialog}>
						{translate('common.cancel')}
					</Button>
					<Button onClick={changeDeliveryman}>
						{translate('distribute.confirmReDistribute')}
					</Button>
				</DialogActions>
			</Dialog>
			<BackDropForDialog />
		</>
	);
};

export default ReDistributeToDeliveryman;
