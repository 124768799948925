export const getRegionLocation = (regionId) => {
    const regions = {
        3: { longitude: 87.637276, latitude: 43.767988 },
        251: { longitude: 76.173991, latitude: 39.713938 },
        292: { longitude: 76.173991, latitude: 39.713938 }
    };
    return regions[regionId];
}

export const getFieldByLang = (item, field = 'name', ignoreUgPrefix = false) => {
    if (!item) return null;
    const locale = localStorage.getItem("lang");
    if (locale === 'zh') {
        return item[`zh_${field}`];
    }
    return ignoreUgPrefix ? item[`${field}`] : item[`ug_${field}`];
}


export const timeToMinute = (time) => {
    let t;
    if (time > -1) {
        const hour = Math.floor(time / 3600);
        const min = Math.floor(time / 60) % 60;
        const sec = time % 60;
        if (hour < 10) {
            t = '0' + hour + ":";
        } else {
            t = hour + ":";
        }
        if (hour === 0) {
            t = '';
        }
        if (min < 10) { t += "0"; }
        t += min + ":";
        if (sec < 10) { t += "0"; }
        t += sec.toFixed(2);
    }
    if (!t) return t;
    t = t.substring(0, t.length - 3);
    return t;


}