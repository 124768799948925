import React from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Divider } from '@mui/material';

const OrderInfoForDialog = ({ order }) => {
	const translate = useTranslate();
	return (
		<Grid container spacing={1}>
			<Box sx={{ bgcolor: '#fff', height: '20px', width: '100%' }} />
			<Grid xs={12} container alignItems='center' justifyContent='center'>
				<Grid xs={2} display='flex' justifyContent='center' alignItems='center'>
					<img
						src='https://bulutbazar.com/assets/front/images/start-point.png'
						alt=''
						width='32px'
						height='32px'
					/>
				</Grid>
				<Divider orientation='vertical' flexItem sx={{ margin: '10px' }} />
				<Grid xs={9}>
					{order?.merchant?.zh_name}
					<br />
					{order?.merchant?.zh_address}
					<br />
					{order?.merchant?.tel}
				</Grid>
			</Grid>
			<Box sx={{ bgcolor: '#fff', height: '20px', width: '100%' }} />

			<Grid xs={12} container alignItems='center' justifyContent='center'>
				<Grid xs={2} display='flex' justifyContent='center' alignItems='center'>
					<img
						src='https://bulutbazar.com/assets/front/images/end-point.png'
						alt=''
						width='32px'
						height='32px'
					/>
				</Grid>
				<Divider orientation='vertical' flexItem sx={{ margin: '10px' }} />
				<Grid xs={9}>
					{order?.receiver}
					<br />
					{order?.community?.zh_name}
					<br />
					{order?.zh_address}
					<br />
					{order?.tel}
				</Grid>
			</Grid>
			<Grid xs={12}>
				<Box sx={{ bgcolor: '#fff', height: '20px', width: '100%' }} />
				{translate('orders.paidAtLabel')} {order?.paid_at}
				<Divider />
				<Box sx={{ bgcolor: '#fff', height: '20px', width: '100%' }} />
				{translate('orders.distanceLabel')} {order?.distance}km
				<Divider />
				<Box sx={{ bgcolor: '#fff', height: '20px', width: '100%' }} />
				{translate('orders.amountLabel')} ￥{order?.total / 100}
				<Divider />
				<Box sx={{ bgcolor: '#fff', height: '20px', width: '100%' }} />
				{translate('orders.currentDeliverymanLabel')}{' '}
				{order?.deliveryman?.zh_name}
				<Divider />
			</Grid>
		</Grid>
	);
};

export default OrderInfoForDialog;
