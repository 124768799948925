import React, { useState, useEffect } from 'react';
import { Map } from '@pansy/react-amap';
import Grid from '@mui/material/Unstable_Grid2';
import dayjs from 'dayjs';
import { Title, useTranslate } from 'react-admin';
import { getRegionLocation } from '../../helpers';
import dataProvider from '../../http';

import OrderList from '../orders/OrderListWithTabs';
import DeliverymanForMap from '../deliverymen/DeliverymanForMap';
import ReDistributeToDeliveryman from './ReDistributeToDeliveryman';
import OrderMarkers from '../orders/OrderMarkers';
import DeliverymanMarkers from '../deliverymen/DeliverymanMarkers';
import DeliverymanInfoWindow from '../deliverymen/DeliverymanInfoWindow';

import './MapDistribute.scss';
import CheckNewOrder from '../../components/CheckNewOrder';

const Distribute = () => {
	const auth = JSON.parse(localStorage.getItem('auth'));
	let regionId = 3;

	if (auth?.user?.agent?.regionId) {
		regionId = auth?.user?.agent?.regionId;
	}

	const [position, setPosition] = useState(getRegionLocation(regionId));
	const translate = useTranslate();
	const [deliverymanInfoVisible, setDeliverymanInfoVisible] = useState(false);
	const [deliverymanOrderList, setDeliverymanOrderList] = useState([]);
	const [orderList, setOrderList] = useState([]);

	const [selectedOrder, selectOrder] = useState();
	const [openOrderDistribute, setOpenOrderDistribute] = useState(false);

	const [deliverymen, setDeliverymen] = useState([]);
	const [selectedDeliveryman, selectDeliveryman] = useState();

	useEffect(() => {
		const fetchDeliverymanOrderList = async () => {
			const filter = {
				deliveryman: selectedDeliveryman.id,
				start_date: dayjs().format('YYYY-MM-DD'),
			};

			const list = await dataProvider.getList('order', {
				filter,
				pagination: { page: 1, perPage: 20 },
				sort: { field: 'id', order: 'ASC' },
			});

			if (list.data) {
				setDeliverymanOrderList(list.data);
			}
		};

		if (selectedDeliveryman) {
			setDeliverymanInfoVisible(true);
			const position = [
				selectedDeliveryman.position.longitude,
				selectedDeliveryman.position.latitude,
			];
			// message.success(`获取的坐标点位置为${position}`);
			setPosition(position);
			fetchDeliverymanOrderList();
		} else {
			setDeliverymanOrderList([]);
			setDeliverymanInfoVisible(false);
		}
	}, [selectedDeliveryman]);

	useEffect(() => {
		if (selectedOrder) {
			setOpenOrderDistribute(true);
		} else {
			setOpenOrderDistribute(false);
		}
	}, [selectedOrder]);

	const displayOrderList =
		deliverymanOrderList.length > 0 ? deliverymanOrderList : orderList;

	return (
		<Grid
			container
			spacing={1}
			style={{ direction: 'ltr' }}
			className='map-distribute'
		>
			<Title title={translate('distribute.mapDistribute')} />
			<Grid xs={4} md={3}>
				<OrderList
					setOrderList={setOrderList}
					setOpenOrderDistribute={setOpenOrderDistribute}
					selectedOrder={selectedOrder}
					selectOrder={selectOrder}
				/>
			</Grid>
			<Grid xs={4} md={6} style={{ height: '100%', maxHeight: '1000px' }}>
				<Map
					zooms={[10, 18]}
					zoom={13}
					center={position}
					className='distribute-map'
				>
					<DeliverymanMarkers
						deliverymen={deliverymen}
						selectDeliveryman={selectDeliveryman}
					/>
					<DeliverymanInfoWindow
						visible={deliverymanInfoVisible}
						position={position}
						deliveryman={selectedDeliveryman}
						setVisible={setDeliverymanInfoVisible}
					/>
					<OrderMarkers
						orderList={displayOrderList}
						selectOrder={selectOrder}
					/>
				</Map>
			</Grid>
			<Grid xs={4} md={3}>
				<DeliverymanForMap
					setDeliverymen={setDeliverymen}
					from='map'
					selectDeliveryman={selectDeliveryman}
					selectedDeliveryman={selectedDeliveryman}
					selectedOrder={selectedOrder}
					selectOrder={selectOrder}
					setOpenOrderDistribute={setOpenOrderDistribute}
				/>
			</Grid>
			<ReDistributeToDeliveryman
				openOrderDistribute={openOrderDistribute}
				setOpenOrderDistribute={setOpenOrderDistribute}
				selectedOrder={selectedOrder}
				deliverymen={deliverymen}
				selectOrder={selectOrder}
			/>
			<CheckNewOrder />
		</Grid>
	);
};

export default Distribute;
