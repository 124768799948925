import React from 'react';
import { useTranslate } from 'react-admin';
import {
	Button,
	Table,
	TableHead,
	TableRow,
	TableBody,
	TableCell,
} from '@mui/material';
import { getFieldByLang } from '../../helpers';
import OrderTime from './OrderTime';
const OrderTable = ({
	orderList,
	selectedOrder,
	selectOrder,
	setOpenOrderDistribute,
}) => {
	const translate = useTranslate();
	return (
		<Table sx={{ padding: 2 }} size='small'>
			<TableHead>
				<TableRow>
					<TableCell>{translate('orders.id')}</TableCell>
					<TableCell>{translate('orders.time')}</TableCell>
					<TableCell>{translate('orders.fromAddress')}</TableCell>
					<TableCell>{translate('orders.tpAddress')}</TableCell>
					<TableCell>{translate('orders.paidAt')}</TableCell>
					<TableCell>{translate('orders.distance')}</TableCell>
					<TableCell>{translate('deliveryman.deliveryman')}</TableCell>

					<TableCell>{translate('common.action')}</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{orderList.map((order) => (
					<TableRow
						key={order.id}
						className={
							selectedOrder && selectedOrder.id === order.id ? 'selected' : ''
						}
					>
						<TableCell>{order.id}</TableCell>
						<TableCell>
							<OrderTime order={order} />
						</TableCell>
						<TableCell>
							{getFieldByLang(order.merchant)} <br />
							{getFieldByLang(order.merchant, 'address')} <br />
							{order.merchant.tel}
						</TableCell>
						<TableCell>
							{getFieldByLang(order.community)} <br />
							{getFieldByLang(order, 'address', true)} <br />
							{order.tel}
						</TableCell>
						<TableCell>{order.paid_at}</TableCell>
						<TableCell>{order.distance}km</TableCell>
						<TableCell>{getFieldByLang(order.deliveryman)}</TableCell>
						<TableCell>
							{selectedOrder && selectedOrder.id === order.id ? (
								<Button
									variant='outlined'
									onClick={() => {
										selectOrder(false);
										setOpenOrderDistribute(false);
									}}
								>
									{translate('common.cancelSelecte')}
								</Button>
							) : (
								<Button
									variant='outlined'
									onClick={() => {
										selectOrder(order);
										setOpenOrderDistribute(true);
									}}
								>
									{translate('distribute.distribute')}
								</Button>
							)}
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
};
export default OrderTable;
