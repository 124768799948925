import React from 'react';
import { InfoWindow } from '@pansy/react-amap';

const DeliverymanInfoWindow = ({
	visible,
	position,
	setVisible,
	deliveryman,
}) => {
	const html = `<div><div style="text-align: center;"><img alt="" src="${deliveryman?.avatar}" width="48px" /></div><h4 style="text-align: center; font-size: 12px;">${deliveryman?.zh_name}<br /> ${deliveryman?.mobile}</h4><p style="text-align: center;font-size: 12px">位置最后更新时间：<br/>${deliveryman?.last_location?.created_at}</p></div>`;

	const infoWindowEvents = {
		open: () => {
			setVisible(true);
		},
		close: () => {
			setVisible(false);
		},
	};
	return (
		<InfoWindow
			position={position}
			visible={visible}
			isCustom={false}
			content={html}
			size={{
				width: 100,
				height: 120,
			}}
			offset={[0, 0]}
			events={infoWindowEvents}
		/>
	);
};

export default DeliverymanInfoWindow;
