import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate, useLocation } from "react-router-dom";

import { useState, useEffect } from "react";

import { useDataProvider, useTranslate } from "react-admin";

const CheckNewOrder = () => {
  const [showNewOrderDialog, setShowNewOrderDialog] = useState(false);
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const navigate = useNavigate();
  const auth = JSON.parse(localStorage.getItem("auth"));
  const { pathname } = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      if (auth && auth.token) {
        dataProvider
          .get("new-orders")
          .then((res) => {
            if (res?.data.url) {
              const audio = new Audio(res.data.url);
              audio.play();
              setShowNewOrderDialog(true);
            }
          })
          .catch((error) => {
            console.log(
              "🚀 ~ file: CheckNewOrder.jsx ~ line 34 ~ dataProvider.get ~ error",
              error
            );
            return {};
          });
      }
    }, 1000 * 45);
    return () => clearInterval(interval);
  }, [auth, dataProvider]);

  return (
    <Dialog
      open={showNewOrderDialog}
      onClose={() => {
        setShowNewOrderDialog(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {translate("orders.new")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {translate("orders.newDesc")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setShowNewOrderDialog(false);
          }}
        >
          {translate("common.cancel")}
        </Button>
        <Button
          onClick={() => {
            if (pathname !== "/map-distribute") {
              navigate("/map-distribute");
            }
            setShowNewOrderDialog(false);
          }}
          autoFocus
        >
          {translate("common.show")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CheckNewOrder;
