import chineseMessages from "ra-language-chinese";
import { mergeTranslations } from "react-admin";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...mergeTranslations(chineseMessages),
  menu: {
    accounts: "账户管理",
    subemployee: "员工管理",
    roles: "角色管理",
    employees: "员工",
    subproduct: "商品管理",
    categories: "商品类目",
    units: "商品单元",
    products: "商品",
    subinventory: "库存管理",
    inventory: "库存查询",
    inventorycount: "库存统计",
    inventorycheck: "盘点历史",
    subpurchase: "采购管理",
    suppliers: "供应商",
    purchase: "采购单",
    subpermission: "会员管理",
    members: "会员",
    levels: "会员等级",
    types: "支付方式",
  },
  common: {
    switch: "切换语言",
    account: "切换账户",
    zhname: "中文名称",
    ugname: "维文名称",
    choose: "账户选择",
    chooseaccount: "请选择账户",
    logintitle: "进销存后台管理系统",
    saveAndShow: "保存并返回",
    saveAndAdd: "保存并继续添加",
    cancel: "取消",
    search: "搜索",
    action: "操作",
    select: "选择",
    cancelSelecte: "取消选择",
    show: "查看",
    backToList: "返回列表",
    zhImage: "中文图片",
    ugImage: "维语图片"
  },
  accounts: {
    mobile: "联系电话",
    member: "登陆账号（电话）",
    password: "登录密码",
    search: "手机号搜索",
    account: "账户",
    contact: "联系人",
    name: "账户名称",
    paytime: "支付时间",
    start: "试用开始",
    end: "试用结束",
    edit: "编辑账户",
    create: "新建账户",
  },
  official: {
    tryaccount: "试用账号",
    tryaccountdel: "到期试用账号",
    account: "正式账户",
    accountdel: "到期正式账户",
    search: "账户搜索",
    start_at: "开始日期",
    end_at: "结束日期",
    expired_at: "到期时间",
    lang: "zh",
  },
  promotion: {
    title: "产品管理",
    name: "产品名称",
    month: "购买几个月",
    gift_month: "赠送几个月",
    price: "价格",
    discounted_price: "折扣价格",
    zhname: "产品中文名称",
    ugname: "产品维文名称",
    edit: "编辑产品",
    create: "新建产品",
  },
  orders: {
    title: "订单管理",
    id: "订单编号",
    created_at: "订单创建时间",
    paidAtLabel: "下单时间：",
    distanceLabel: "距离：",
    amountLabel: "金额：",
    currentDeliverymanLabel: "目前的配送员：",
    orderNoLabel: "订单—%{orderNo}",
    fromAddress: "取货地址",
    tpAddress: "送货地址",
    paidAt: "下单时间",
    distance: "距离",
    newOrders: "新订单-待接单",
    acceptedOrders: "已接单-取单中",
    deliveringOrders: "已取单-配送中",
    accepted: "已接单",
    delivering: "配送中",
    completed: "已完成",
    receiver: "收货人",
    address: "收货地址",
    new: "新订单提醒",
    newDesc: "您有新的订单请及时处理",
    time: "订单时间",
  },
  deliveryman: {
    title: "配送员管理",
    deliveryman: "配送员",
    mobile: "手机",
    name: "姓名",
    ug_name: "配送员名称（维语）",
    zh_name: "配送员经常（汉语）",
    edit: "编辑配送员",
    new: "增加配送员",
  },
  station: {
    title: "配送站管理",
    manager: "管理员",
    edit: "编辑配送站",
    new: "增加配送站",
    status: "状态",
    latitude: "维度",
    longitude: "经度",
    ug_name: "配送站名称（维语）",
    zh_name: "配送站名称（汉语）",
    ug_address: "配送站地址（维语）",
    zh_address: "配送站地址（汉语）",
    community: "小区",
    name: "配送站",
  },
  merchant: {
    title: "商家管理",
    manager: "管理员",
    edit: "编辑商家",
    new: "增加商家",
    status: "状态",
    latitude: "维度",
    longitude: "经度",
    ug_name: "商家名称（维语）",
    zh_name: "商家经常（汉语）",
    ug_address: "商家地址（维语）",
    zh_address: "商家地址（汉语）",
    community: "小区",
    type: "商家类型",
    logo: "商家LOGO",
    merchant: "商家",
  },
  distribute: {
    title: "调度管理",
    mapDistribute: "地图调度",
    demo: "demo",
    chooseDeliveryman: "还没有选择配送员哦",
    reDistribute: "重新分配配送员",
    confirmReDistribute: "确定更换配送员",
    distribute: "指派",
  },
  advertisements: {
    title: "广告管理",
    slider: "滑动广告",
    addSlider: "增加滑动广告",
    editSlider: "编辑滑动广告",
    splash: "首屏广告",
    addSplash: "增加首屏广告",
    editSplash: "编辑首屏广告",
    adsImage: "广告图片",
    adsName: "广告名称",
    adsOrder: "广告排序",
    adsStatus: "广告状态",
    merchantName: "商家名称",
    open: "开启",
    close: "关闭",
  },
};
