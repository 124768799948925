import { useEffect, useState } from 'react';
import { Card, TextField, Box, Toolbar } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useGetList, useTranslate } from 'react-admin';
import dayjs from 'dayjs';
import { CustomPagination } from '../../components';
import OrderTable from './OrderTable';

const OrderList = ({
	setOpenOrderDistribute,
	selectedOrder,
	selectOrder,
	setOrderList,
	status,
}) => {
	const translate = useTranslate();
	const [page, setPage] = useState(1);
	const [rowsPerPage] = useState(10);
	const [filter, setFilter] = useState({
		status: 'new',
		start_date: dayjs().format('YYYY-MM-DD'),
	});

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const { data, total, isLoading } = useGetList('order', {
		filter: { ...filter, status },
		pagination: { page, perPage: rowsPerPage },
		sort: { field: 'id', order: 'DESC' },
	});
	useEffect(() => {
		if (setOrderList) {
			setOrderList(data);
		}
	}, [data, setOrderList]);
	if (isLoading) {
		return <div>Loading...</div>;
	}

	return (
		<Box className='order-list'>
			<TextField
				label={translate('common.search')}
				value={filter?.search}
				onChange={(e) => setFilter({ ...filter, search: e.target.value })}
				variant='filled'
				size='small'
				margin='dense'
			/>
			<Card>
				<OrderTable
					orderList={data}
					selectedOrder={selectedOrder}
					selectOrder={selectOrder}
					setOpenOrderDistribute={setOpenOrderDistribute}
				/>
			</Card>
			<Toolbar>
				<Stack spacing={2}>
					<CustomPagination
						total={total}
						rowsPerPage={rowsPerPage}
						page={page}
						handleChangePage={handleChangePage}
					/>
				</Stack>
			</Toolbar>
		</Box>
	);
};

export default OrderList;
