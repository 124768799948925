// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ra: {
    notification: {
      updated: "مۇۋەپپەقىيەتلىك يېڭىلاندى",
      deleted: "مۇۋەپپەقىيەتلىك ئۆچۈرۈلدى",
      item_doesnt_exist: "بۇ مەزمۇن تېپىلمىدى",
      created: "مۇۋەپپەقىيەتلىك قوشۇلدى",
    },
    validation: {
      required: "چوقۇم تولدۇرۇسىز",
    },
    input: {
      references: {
        single_missing: "مۇناسىۋەتلىك مەزمۇن تېپىلمىدى",
      },

      image: {
        upload_single:
          "رەسىمنى بۇيەرگە تارتىپ ئەكىرسىڭىز ياكى بۇ يەرنى بېسىپ رەسىم يوللىسىڭىز بولىدۇ.",
        upload_several:
          "رەسىمنى بۇيەرگە تارتىپ ئەكىرسىڭىز ياكى بۇ يەرنى بېسىپ رەسىم يوللىسىڭىز بولىدۇ.",
      },
    },
    auth: {
      auth_check_error: "كىرىپ مەشغۇلات قىلىڭ",
      sign_in: "كىرىش",
      username: "ئەزا نامى",
      password: "پارول",
      logout: "چېكىنىش",
      user_menu: "تەڭشەك",
    },
    action: {
      remove: "ئۆچۈرۈش",
      unselect: "تاللانمىغان",
      open_menu: "ئېچىش",
      close_menu: "تاقاش",
      undo: "قايتۇرۇش",
      sort: "رەتكە تىزىش",
      undi: "قايتۇرۇش",
      back: "قايتۇرۇش",
      add: "قوشۇش",
      save: "ساقلاش",
      create: "قوشۇش",
      export: "جەدۋەل",
      edit: "تەھرىرلەش",
      refresh: "يېڭىلاش",
      delete: "ئۆچۈۈش",
      bulk_actions: "كۆپ تاللاش",
    },
    message: {
      details: "تېخىۇ كۆپ خاتالىق ئۇچۇرىنى كۆرۈش",
      error: "كۈتۈلمىگەن خاتالىق كۆرۈلدى، قايتا سىناڭ",
    },
    navigation: {
      skip_nav: "",
      prev: "ئالدىدىكىسى",
      next: "كەينىدىكىسى",
      page_rows_per_page: "ھەر بەتتە",
      page_range_info: "",
      no_results: "نەتىجىسى قۇرۇق",
    },
    page: {
      list: "تىزىملىك",
      error: "خاتالىق كۆرۈلدى",
      dashboard: "ئارقا سۇپا",
      invite: "يېڭىدىن قوشامسىز؟",
      empty: "مەزمۇن يوق",
      create: "قوشۇش",
    },
  },
  menu: {
    accounts: "ئاكونت باشقۇرۇش",
    subemployee: "خادىم باشقۇرۇش",
    roles: "ھوقۇق باشقۇرۇش",
    employees: "خادىملار",
    subproduct: "مەھسۇلات باشقۇرۇش",
    categories: "مەھسۇلات تۈرى",
    units: "مەھسۇلات بىرلىكى",
    products: "مەھسۇلاتلار",
    subinventory: "ئامبار باشقۇرۇش",
    inventory: "ئامبار قالدۇقى",
    inventorycount: " ئامبار سانىقى",
    inventorycheck: "ساناق تارىخى",
    subpurchase: "مال كىرگۈزۈش",
    suppliers: "مال تەمىنلىگۈچى",
    purchase: "مال كىرگۈزۈش تالونى",
    subpermission: "ئەزا باشقۇرۇش",
    members: "ئەزالار",
    levels: "ئەزا دەرىجىسى",
    types: "پۇل تۆلەش شەكلى",
  },
  common: {
    switch: "تىل ئالماشتۇرۇش",
    account: "ئاكونت ئالماشتۇرۇش",
    zhname: "خەنزۇچە ئىسمى",
    ugname: "ئۇيغۇرچە ئىسمى",
    choose: "ئاكونت تاللاش",
    chooseaccount: "ئاكونت تاللاش",
    logintitle: "بۇلۇت بازار باشقۇرۇش سۇپىسى",
    saveAndShow: "ساقلاپ قايتىش",
    saveAndAdd: "داۋاملىق قوشۇش",
    cancel: "قالدۇرۇش",
    search: "ئىزدەش",
    action: "مەشغۇلات",
    select: "تاللاش",
    cancelSelecte: "قالدۇرۇش",
    show: "كۆرۈش",
    backToList: "تىزىملىككە قايتىش",
    zhImage: "خەنزۇچە رەسىم",
    ugImage: "ئۇيغۇرچە رەسىم"
  },
  accounts: {
    mobile: "يانفۇن",
    member: "كىرىش يانفۇن",
    password: "مەخپى نومۇر",
    search: "ئىزدەش",
    account: "ئاكونت",
    contact: "ئالاقىلاشقۇچى",
    name: "ئاكونت نامى",
    paytime: "پۇل تۆلىگەن ۋاقتى",
    start: "ئىشلىتىشكە باشلىغان ۋاقتى",
    end: "ئاخىرلىشىش ۋاقتى",
    edit: "ئاكونت تەھرىرلەش",
    create: "ئاكونت قوشۇش",
  },
  official: {
    tryaccount: "سىناق ئاككونت",
    tryaccountdel: "ۋاقتى توشقان سىناق ئ‍اككونت",
    account: "رەسمى ئاككونت",
    accountdel: "ۋاقتى توشقان رەسمى ئاككونت",
    search: "ئىزدەش",
    start_at: "باشلىنىش ۋاقتى",
    end_at: "ئاياغلىشىش ۋاقتى",
    expired_at: "ئاخىرلىشىش ۋاقتى",
    lang: "ug",
  },
  orders: {
    title: "زاكاز باشقۇرۇش",
    id: "زاكاز نومۇرى",
    created_at: "چۈشۈرگەن ۋاقىت",
    paidAtLabel: "ۋاقتى：",
    distanceLabel: "ئارلىقى：",
    amountLabel: "سوممىسى：",
    currentDeliverymanLabel: "ھازىرقى توشۇغۇچى：",
    orderNoLabel: "زاكاز—%{orderNo}",
    fromAddress: "باشلىنىش ئورنى",
    tpAddress: "يەتكۈزۈش ئورنى",
    paidAt: "ۋاقتى",
    distance: "ئارلىقى",
    newOrders: "يېڭى زاكازلار",
    acceptedOrders: "قوبۇل قىلىنغان",
    deliveringOrders: "توشۇلىۋاتقان",
    accepted: "قوبۇللانغان",
    delivering: "توشۇلىۋاتقان",
    completed: "تاماملانغان",
    receiver: "تاپشۇرىۋالغۇچى",
    address: "ئ‍ادرېسى",
    amount: "سوممىسى",
    new: "يېڭى زاكاس بايقالدى",
    newDesc: "يېڭى زاكاس بايقالدى، تىېز بىر تەرەپ قىلىڭ",
    time: "ۋاقتى",
  },
  deliveryman: {
    title: "توشۇغۇچى باشقۇرۇش",
    deliveryman: "توشۇغۇچى",
    mobile: "يانفۇن",
    name: "ئىسىم",
    ug_name: "توشۇغۇچى ئىسمى(ئۇيغۇرچە)",
    zh_name: "توشۇغۇچى ئىسمى(خەنزۇچە)",
    edit: "تەھرىرلەش",
    new: "قوشۇش",
  },
  station: {
    title: "پونكىت باشقۇرۇش",
    manager: "باشقۇرغۇچى",
    edit: "تەھرىرلەش",
    new: "قوشۇش",
    status: "ھالىتى",
    latitude: "پارالىل",
    longitude: "مېردىئان",
    ug_name: "پونكىت نامى(ئۇيغۇرچە)",
    zh_name: "پونكىت نامى(خەنزۇچە)",
    ug_address: "ئادرېس(ئ‍ۇيغۇرچە)",
    zh_address: "ئ‍ادرېس (خەنزۇچە)",
    community: "قورو",
  },
  merchant: {
    title: "دۇكان باشقۇرۇش",
    manager: "باشقۇرغۇچى",
    edit: "تەھرىرلەش",
    new: "قوشۇش",
    status: "ھالىتى",
    latitude: "پارالېل",
    longitude: "مېردىئان",
    ug_name: "دۇكان نامى(ئۇيغۇرچە)",
    zh_name: "دۇكان نامى(خەنزۇچە)",
    ug_address: "ئادرېس(ئ‍ۇيغۇرچە)",
    zh_address: "ئ‍ادرېس (خەنزۇچە)",
    community: "قورو",
    type: "دۇكان شەكلى",
    logo: "دۇكان LOGO",
    merchant: "دۇكان",
  },
  distribute: {
    title: "زاكاز بۆلۈش",
    mapDistribute: "خەرىتىدە بۆلۈش",
    demo: "demo",
    chooseDeliveryman: "توشۇغۇچى تاللىماپسىز",
    reDistribute: "قايتا بۆلۈش",
    confirmReDistribute: "راستىنلا ئالماشتۇرامسىز؟",
    distribute: "بۆلۈش",
  },
  advertisements: {
    title: "广告管理",
    slider: "滑动广告",
    addSlider: "增加滑动广告",
    editSlider: "编辑滑动广告",
    splash: "首屏广告",
    addSplash: "增加首屏广告",
    editSplash: "编辑首屏广告",
    adsImage: "广告图片",
    adsName: "广告名称",
    adsOrder: "广告排序",
    adsStatus: "广告状态",
    merchantName: "商家名称",
    open: "开启",
    close: "关闭",
  },
};
