import { useState } from 'react';
import { useTranslate } from 'react-admin';

import { Box, Tab } from '@mui/material';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import OrderList from './OrderList';

const OrderListWithTabs = ({
	setOrderList,
	setOpenOrderDistribute,
	selectedOrder,
	selectOrder,
}) => {
	const [value, setValue] = useState('1');

	const translate = useTranslate();

	const handleTabChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: '100%', typography: 'body1' }}>
			<TabContext value={value}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList onChange={handleTabChange} centered>
						<Tab label={translate('orders.newOrders')} value='1' />
						<Tab label={translate('orders.acceptedOrders')} value='2' />
						<Tab label={translate('orders.deliveringOrders')} value='3' />
					</TabList>
				</Box>
				<TabPanel value='1'>
					<OrderList
						setOrderList={setOrderList}
						setOpenOrderDistribute={setOpenOrderDistribute}
						selectedOrder={selectedOrder}
						selectOrder={selectOrder}
						status='new'
					/>
				</TabPanel>
				<TabPanel value='2'>
					<OrderList
						setOrderList={setOrderList}
						setOpenOrderDistribute={setOpenOrderDistribute}
						selectedOrder={selectedOrder}
						selectOrder={selectOrder}
						status='accepted'
					/>
				</TabPanel>
				<TabPanel value='3'>
					<OrderList
						setOrderList={setOrderList}
						setOpenOrderDistribute={setOpenOrderDistribute}
						selectedOrder={selectedOrder}
						selectOrder={selectOrder}
						status='delivering'
					/>
				</TabPanel>
			</TabContext>
		</Box>
	);
};
export default OrderListWithTabs;
