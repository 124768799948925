import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  required,
  List,
  SimpleForm,
  SimpleList,
  TextField,
  TextInput,
  useTranslate,
  Filter,
  AutocompleteInput,
  ReferenceInput,
  DeleteButton,
  BooleanInput,
  NumberInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ImageField,
} from "react-admin";

import { CreateToolbar, TopBarActions } from "../../components";

const MerchantFilter = (props) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <TextInput label={translate("deliveryman.name")} source="name" alwaysOn />
      <TextInput
        label={translate("deliveryman.mobile")}
        source="mobile"
        alwaysOn
      />
    </Filter>
  );
};

export const MerchantList = (props) => {
  const isSmall = false;

  const translate = useTranslate();
  return (
    <List
      {...props}
      title={translate("merchant.title")}
      filters={<MerchantFilter />}
      className="title"
    >
      {isSmall ? (
        <SimpleList
          primaryText={() => translate("deliveryman.deliveryman")}
          secondaryText={(record) =>
            `${record.zh_name} ${record.ug_name}  ${record.mobile}`
          }
        />
      ) : (
        <Datagrid>
          <ImageField
            source="avatar"
            title={translate("merchant.logo")}
            label={translate("merchant.logo")}
          />

          <TextField label={translate("merchant.ug_name")} source="ug_name" />
          <TextField label={translate("merchant.zh_name")} source="zh_name" />
          <TextField label={translate("merchant.type")} source="type_label" />
          <TextField label={translate("deliveryman.mobile")} source="tel" />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      )}
    </List>
  );
};

const managerLabel = (record) => `${record.nickname} ${record.mobile}`;
const communityLabel = (record) => `${record.zh_name} ${record.ug_name}`;

export const MerchantEdit = (props) => {
  const translate = useTranslate();
  return (
    <Edit
      {...props}
      title={translate("station.edit")}
      actions={<TopBarActions />}
    >
      <SimpleForm>
        <ReferenceInput
          validate={required()}
          reference="managers"
          source="manager_id"
          label={translate("station.manager")}
        >
          <AutocompleteInput
            label={translate("station.manager")}
            optionText={managerLabel}
            optionValue="id"
          />
        </ReferenceInput>

        <TextInput
          source="ug_name"
          label={translate("station.ug_name")}
          validate={required()}
        />
        <TextInput
          source="zh_name"
          label={translate("station.zh_name")}
          validate={required()}
        />
        <BooleanInput label={translate("station.status")} source="status" />
        <TextInput
          source="ug_address"
          label={translate("station.ug_address")}
          validate={required()}
        />
        <TextInput
          source="zh_address"
          label={translate("station.zh_address")}
          validate={required()}
        />
        <NumberInput source="latitude" label={translate("station.latitude")} />
        <NumberInput
          source="longitude"
          label={translate("station.longitude")}
        />
        <ReferenceArrayInput
          label={translate("station.community")}
          source="community_ids"
          reference="community"
          perPage={100}
        >
          <SelectArrayInput
            optionText={communityLabel}
            label={translate("station.community")}
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export const MerchantCreate = (props) => {
  const translate = useTranslate();

  return (
    <Create
      {...props}
      title={translate("station.new")}
      actions={<TopBarActions />}
    >
      <SimpleForm toolbar={<CreateToolbar />}>
        <ReferenceInput
          validate={required()}
          reference="managers"
          source="manager_id"
          label={translate("station.manager")}
        >
          <AutocompleteInput
            label={translate("station.manager")}
            optionText={managerLabel}
            optionValue="id"
          />
        </ReferenceInput>

        <TextInput
          source="ug_name"
          label={translate("station.ug_name")}
          validate={required()}
        />
        <TextInput
          source="zh_name"
          label={translate("station.zh_name")}
          validate={required()}
        />
        <BooleanInput label={translate("station.status")} source="status" />
        <TextInput
          source="ug_address"
          label={translate("station.ug_address")}
          validate={required()}
        />
        <TextInput
          source="zh_address"
          label={translate("station.zh_address")}
          validate={required()}
        />
        <NumberInput source="latitude" label={translate("station.latitude")} />
        <NumberInput
          source="longitude"
          label={translate("station.longitude")}
        />
        <ReferenceArrayInput
          label={translate("station.community")}
          source="community_ids"
          reference="community"
          perPage={100}
        >
          <SelectArrayInput
            optionText={communityLabel}
            label={translate("station.community")}
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};
