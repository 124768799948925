import { useState } from 'react';
import { useGetList, useTranslate } from 'react-admin';
import { Card, TextField, Box, Toolbar } from '@mui/material';

import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import { CustomPagination } from '../../components';
import OrderTable from './OrderTable';

const DeliverymanOrderList = ({
	deliverymanId,
	setOpenOrderDistribute,
	selectOrder,
	selectedOrder,
}) => {
	const translate = useTranslate();
	const [filter, setFilter] = useState({
		deliveryman: deliverymanId,
		start_date: dayjs().format('YYYY-MM-DD'),
	});
	const [page, setPage] = useState(1);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const rowsPerPage = 8;
	const { data, total, isLoading } = useGetList('order', {
		filter,
		pagination: { page, perpage: rowsPerPage },
		sort: { field: 'id', order: 'ASC' },
	});

	if (isLoading) {
		return <div>Loading...</div>;
	}

	return (
		<Box
			sx={{
				minWidth: '400px',
			}}
		>
			<TextField
				label={translate('common.search')}
				value={filter?.search}
				onChange={(e) => setFilter({ ...filter, search: e.target.value })}
				variant='filled'
				size='small'
				margin='dense'
			/>
			<Card>
				<OrderTable
					orderList={data}
					selectedOrder={selectedOrder}
					selectOrder={selectOrder}
					setOpenOrderDistribute={setOpenOrderDistribute}
				/>
			</Card>
			<Toolbar>
				<Stack spacing={2}>
					<CustomPagination
						total={total}
						rowsPerPage={rowsPerPage}
						page={page}
						handleChangePage={handleChangePage}
					/>
				</Stack>
			</Toolbar>
		</Box>
	);
};
export default DeliverymanOrderList;
