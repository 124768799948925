import { useEffect, useState } from 'react';
import { useGetList, useTranslate } from 'react-admin';
import {
	Card,
	Box,
	Toolbar,
	Table,
	TableHead,
	TableRow,
	TableBody,
	TableCell,
} from '@mui/material';

import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

import DeliverymanOrderList from '../orders/DeliverymanOrderList';
import { CustomPagination } from '../../components';

const DeliverymanForMap = ({
	setDeliverymen,
	from,
	selectDeliveryman,
	selectedDeliveryman,
	setOpenOrderDistribute,
	selectOrder,
}) => {
	const [filter] = useState({
		type: 'distribute',
	});
	const translate = useTranslate();
	const [page, setPage] = useState(1);
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const perPage = 20;
	const { data, total, isLoading } = useGetList('deliveryman', {
		filter,
		pagination: { page, perPage },
		sort: { field: 'id', order: 'ASC' },
	});

	useEffect(() => {
		if (setDeliverymen) {
			setDeliverymen(data);
		}
	}, [data, setDeliverymen]);

	if (isLoading) {
		return <div>Loading...</div>;
	}
	return (
		<Box className='deliveryman-list'>
			<Card>
				<Table sx={{ padding: 2 }} size='small' className='table'>
					<TableHead>
						<TableRow>
							<TableCell>{translate('deliveryman.deliveryman')}</TableCell>
							<TableCell>{translate('orders.accepted')}</TableCell>
							<TableCell>{translate('orders.delivering')}</TableCell>
							<TableCell>{translate('common.action')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody className='body'>
						{data.map((deliveryman) => (
							<TableRow
								key={deliveryman.id}
								className={
									selectedDeliveryman &&
									selectedDeliveryman.id === deliveryman.id
										? 'selected'
										: ''
								}
							>
								<TableCell>{deliveryman.zh_name}</TableCell>
								<TableCell>{deliveryman.recieved_count}</TableCell>
								<TableCell>{deliveryman.delivering_count}</TableCell>
								<TableCell>
									{from && from === 'map' && deliveryman.position && (
										<div>
											{selectedDeliveryman &&
											selectedDeliveryman.id === deliveryman.id ? (
												<div
													onClick={() => {
														selectDeliveryman();
													}}
												>
													{translate('common.cancelSelecte')}
												</div>
											) : (
												<div
													onClick={() => {
														selectDeliveryman(deliveryman);
													}}
												>
													{translate('common.select')}
												</div>
											)}
										</div>
									)}
									<PopupState variant='popover' popupId='demo-popup-popover'>
										{(popupState) => (
											<div>
												<span variant='contained' {...bindTrigger(popupState)}>
													{translate('common.show')}
												</span>
												<Popover
													{...bindPopover(popupState)}
													anchorOrigin={{
														vertical: 'bottom',
														horizontal: 'center',
													}}
													transformOrigin={{
														vertical: 'top',
														horizontal: 'center',
													}}
												>
													<DeliverymanOrderList
														setOpenOrderDistribute={setOpenOrderDistribute}
														selectOrder={selectOrder}
														deliverymanId={deliveryman.id}
													/>
												</Popover>
											</div>
										)}
									</PopupState>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Card>
			<Toolbar>
				<Stack spacing={2}>
					<CustomPagination
						total={total}
						rowsPerPage={perPage}
						page={page}
						handleChangePage={handleChangePage}
					/>
				</Stack>
			</Toolbar>
		</Box>
	);
};
export default DeliverymanForMap;
