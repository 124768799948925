import React, { useState, useEffect } from 'react';
import { Title, useTranslate } from 'react-admin';
import Grid from '@mui/material/Unstable_Grid2';
import OrderList from '../orders/OrderListWithTabs';
import DeliverymanList from '../deliverymen/DeliverymanList';
import ReDistributeToDeliveryman from './ReDistributeToDeliveryman';
import './Distribute.scss';
import CheckNewOrder from '../../components/CheckNewOrder';

const Distribute = () => {
	const [selectedOrder, selectOrder] = useState();
	const [openOrderDistribute, setOpenOrderDistribute] = useState(false);
	const [deliverymen, setDeliverymen] = useState([]);
	const [selectedDeliveryman, selectDeliveryman] = useState();
	const translate = useTranslate();

	useEffect(() => {
		if (selectedOrder) {
			setOpenOrderDistribute(true);
		} else {
			setOpenOrderDistribute(false);
		}
	}, [selectedOrder]);

	return (
		<Grid container spacing={1} className='distribute'>
			<Title title={translate('distribute.title')} />
			<Grid xs={6} md={4}>
				<DeliverymanList
					setDeliverymen={setDeliverymen}
					from='map'
					selectDeliveryman={selectDeliveryman}
					selectedDeliveryman={selectedDeliveryman}
					selectedOrder={selectedOrder}
					selectOrder={selectOrder}
					setOpenOrderDistribute={setOpenOrderDistribute}
				/>
			</Grid>
			<Grid xs={6} md={8}>
				<OrderList selectOrder={selectOrder} />
			</Grid>
			<ReDistributeToDeliveryman
				openOrderDistribute={openOrderDistribute}
				setOpenOrderDistribute={setOpenOrderDistribute}
				selectedOrder={selectedOrder}
				deliverymen={deliverymen}
				selectOrder={selectOrder}
			/>
			<CheckNewOrder />
		</Grid>
	);
};

export default Distribute;
