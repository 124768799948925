import * as React from "react";
import { memo } from "react";
import {
  Layout,
  AppBar,
  UserMenu,
  useLocaleState,
  useTranslate,
} from "react-admin";
import { MenuItem, ListItemIcon } from "@mui/material";

import Language from "@mui/icons-material/Language";
import { useNavigate, useLocation } from "react-router-dom";
import Menu from "./Menu";

const SwitchLanguage = (props) => {
  const [locale, setLocale] = useLocaleState();

  const translate = useTranslate();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <MenuItem
      key={props?.key}
      className="menuItem"
      onClick={() => {
        const newLocale = locale === "ug" ? "zh" : "ug";
        setLocale(newLocale);
        localStorage.setItem("lang", newLocale);
        navigate.go(pathname);
      }}
    >
      <ListItemIcon className="icon">
        <Language />
      </ListItemIcon>
      {translate("common.switch")}
    </MenuItem>
  );
};

const MyUserMenu = (props) => (
  <UserMenu {...props}>
    <SwitchLanguage key="switch" />
  </UserMenu>
);

const MyAppBar = memo((props) => (
  <AppBar {...props} userMenu={<MyUserMenu />} />
));

const MyLayout = (props) => {
  return <Layout {...props} appBar={MyAppBar} menu={Menu} />;
};

export default MyLayout;
