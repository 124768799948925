import * as React from "react";
import { DashboardMenuItem, MenuItemLink, useTranslate } from "react-admin";
import SubMenu from "./SubMenu";
import ListAltIcon from "@mui/icons-material/ListAlt";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import ForkLeftIcon from "@mui/icons-material/ForkLeft";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ImageIcon from "@mui/icons-material/Image";

const Menu = ({ dense = false }) => {
  const translate = useTranslate();
  const [state, setState] = React.useState({
    menuAds: true,
  });

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div className="">
      <DashboardMenuItem />

      <MenuItemLink
        to="/merchant"
        primaryText={translate("merchant.title")}
        leftIcon={<StorefrontIcon />}
        dense={dense}
      />
      <MenuItemLink
        to="/deliveryman"
        primaryText={translate("deliveryman.title")}
        leftIcon={<GroupAddIcon />}
        dense={dense}
      />
      <MenuItemLink
        to="/station"
        primaryText={translate("station.title")}
        leftIcon={<DeliveryDiningIcon />}
        dense={dense}
      />
      <MenuItemLink
        to="/order"
        primaryText={translate("orders.title")}
        leftIcon={<ListAltIcon />}
        dense={dense}
      />

      <SubMenu
        isOpen={state.menuAds}
        handleToggle={() => handleToggle("menuAds")}
        name={translate("advertisements.title")}
        icon={<ImageIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/elan"
          primaryText={translate("advertisements.slider")}
          leftIcon={<ImageIcon />}
          dense={dense}
        />
        <MenuItemLink
          to="/elanslash"
          primaryText={translate("advertisements.splash")}
          leftIcon={<ImageIcon />}
          dense={dense}
        />
      </SubMenu>

      <MenuItemLink
        to="/distribute"
        primaryText={translate("distribute.title")}
        leftIcon={<ForkLeftIcon />}
        dense={dense}
      />
      <MenuItemLink
        to="/map-distribute"
        primaryText={translate("distribute.mapDistribute")}
        leftIcon={<AddLocationAltIcon />}
        dense={dense}
      />
    </div>
  );
};

export default Menu;
