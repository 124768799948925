import React from 'react';
import { useTranslate } from 'react-admin';

import {
	Table,
	TableHead,
	TableRow,
	TableBody,
	TableCell,
} from '@mui/material';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

import DeliverymanOrderList from '../orders/DeliverymanOrderList';
import { getFieldByLang } from '../../helpers';

const DeliverymanTable = ({
	deliverymanList,
	setOpenOrderDistribute,
	selectOrder,
}) => {
	const translate = useTranslate();
	return (
		<Table sx={{ padding: 2 }} size='small' className='table'>
			<TableHead>
				<TableRow>
					<TableCell>{translate('deliveryman.deliveryman')}</TableCell>
					<TableCell>{translate('orders.accepted')}</TableCell>
					<TableCell>{translate('orders.delivering')}</TableCell>
					<TableCell>{translate('orders.completed')}</TableCell>
					<TableCell>{translate('common.action')}</TableCell>
				</TableRow>
			</TableHead>
			<TableBody className='body'>
				{deliverymanList.map((deliveryman) => (
					<TableRow key={deliveryman.id} className='row'>
						<TableCell>{getFieldByLang(deliveryman)}</TableCell>
						<TableCell>{deliveryman.recieved_count}</TableCell>
						<TableCell>{deliveryman.delivering_count}</TableCell>
						<TableCell>{deliveryman.delivered_count}</TableCell>
						<TableCell>
							<PopupState variant='popover' popupId='demo-popup-popover'>
								{(popupState) => (
									<div>
										<span variant='contained' {...bindTrigger(popupState)}>
											{translate('common.show')}
										</span>
										<Popover
											{...bindPopover(popupState)}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'center',
											}}
											transformOrigin={{
												vertical: 'top',
												horizontal: 'center',
											}}
										>
											<DeliverymanOrderList
												setOpenOrderDistribute={setOpenOrderDistribute}
												selectOrder={selectOrder}
												deliverymanId={deliveryman.id}
											/>
										</Popover>
									</div>
								)}
							</PopupState>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
};
export default DeliverymanTable;
