import { Grid } from '@mui/material';
import {
	ChipField,
	Datagrid,
	Filter,
	List,
	SimpleList,
	FunctionField,
	TextInput,
	useTranslate,
	WrapperField,
	DateField,
} from 'react-admin';
import { getFieldByLang } from '../../helpers';

export const OrderssList = (props) => {
	const translate = useTranslate();
	const isSmall = false;

	const OrderFilter = (props) => {
		return (
			<Filter {...props}>
				<TextInput
					label={translate('deliveryman.name')}
					source='name'
					alwaysOn
				/>
				<TextInput
					label={translate('deliveryman.mobile')}
					source='mobile'
					alwaysOn
				/>
			</Filter>
		);
	};

	const PostPanel = ({ record }) => (
		<div style={{ display: 'flex' }}>
			<div style={{ margin: '0 3vw 0 3vw' }} />
			<div>{translate('accounts.name')}：</div>
			<div dangerouslySetInnerHTML={{ __html: record.receiver }} />
			<div style={{ margin: '0 3vw 0 3vw' }} />
			<div>{translate('accounts.mobile')}：</div>
			<div
				dangerouslySetInnerHTML={{
					__html: record.tel,
				}}
			/>
			<div style={{ margin: '0 3vw 0 3vw' }} />
			<div>{translate('accounts.contact')}：</div>
			<div dangerouslySetInnerHTML={{ __html: record.address }} />
			<div style={{ margin: '0 3vw 0 3vw' }} />
			<div>{translate('accounts.paytime')}：</div>
			<div dangerouslySetInnerHTML={{ __html: record.distance }} />
		</div>
	);

	return (
		<List
			{...props}
			title={translate('deliveryman.title')}
			filters={<OrderFilter />}
			className='title'
			sort={{ field: 'id', order: 'DESC' }}
		>
			{isSmall ? (
				<SimpleList
					primaryText={() => translate('deliveryman.deliveryman')}
					secondaryText={(record) =>
						`${record.zh_name} ${record.ug_name}  ${record.mobile}`
					}
				/>
			) : (
				<Datagrid expand={PostPanel}>
					<WrapperField label={translate('orders.receiver')}>
						<Grid
							display='flex'
							justifyContent='center'
							alignItems='center'
							flexDirection='column'
						>
							<FunctionField
								label={translate('orders.receiver')}
								render={(record) => {
									return getFieldByLang(record, 'receiver', true);
								}}
							/>
							<ChipField source='tel' />
						</Grid>
					</WrapperField>

					<FunctionField
						label={translate('orders.address')}
						render={(record) => {
							return getFieldByLang(record, 'address', true);
						}}
					/>
					<FunctionField
						label={translate('merchant.merchant')}
						render={(record) => {
							return getFieldByLang(record.merchant);
						}}
					/>
					<DateField
						source='payed_at'
						label={translate('orders.paidAt')}
						showTime
						sortByOrder='DESC'
					/>
					<FunctionField
						label={translate('orders.amount')}
						render={(record) => {
							return `￥${record.total / 100}`;
						}}
					/>

					<FunctionField
						label={translate('orders.distance')}
						render={(record) => {
							if (record.distance < 1) {
								return `${record.distance * 1000}m`;
							}
							return `${record.distance}km`;
						}}
					/>
				</Datagrid>
			)}
		</List>
	);
};
