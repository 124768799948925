import React from 'react';

import { Marker, Polyline } from '@pansy/react-amap';
const OrderMarkers = ({ orderList, selectOrder }) => {
	const mapOrderEvents = {
		click: (event) => {
			selectOrder(event.target._opts.extData);
		},
	};

	if (!orderList) {
		return null;
	}
	return (
		<>
			{orderList.map((order, index) => {
				if (!order.position) {
					return null;
				}
				return (
					<div key={index}>
						<Marker
							className='marker'
							clickable
							key={index + 1000}
							position={order.position}
							anchor={[0, 0]}
							size={[48, 48]}
							offset={[-16, -32]}
							extData={order}
							label={order.zh_name}
							events={mapOrderEvents}
							// icon={item.bigIcon}
						>
							<div className='image'>
								<img
									src='https://bulutbazar.com/assets/front/images/end-point.png'
									width='32'
									alt={order.receiver}
								/>
							</div>
						</Marker>
						<Marker
							className='marker'
							clickable
							key={index}
							position={{
								latitude: order.merchant.latitude,
								longitude: order.merchant.longitude,
							}}
							anchor={[0, 0]}
							offset={[-16, -32]}
							size={[48, 48]}
							extData={order}
							label={order.zh_name}
							events={mapOrderEvents}
							// icon={item.bigIcon}
						>
							<div className='image'>
								<img
									src='https://bulutbazar.com/assets/front/images/start-point.png'
									width='32'
									alt={order.receiver}
								/>
							</div>
						</Marker>
						<Polyline
							path={[
								[order.position.longitude, order.position.latitude],
								[order.merchant.longitude, order.merchant.latitude],
							]}
						/>
					</div>
				);
			})}
		</>
	);
};

export default OrderMarkers;
