import { useEffect, useState } from "react";
import { useGetList } from "react-admin";
import { Card, Box, Toolbar } from "@mui/material";
import Stack from "@mui/material/Stack";

import { CustomPagination } from "../../components";
import DeliverymanTable from "./DeliverymanTable";
// import CheckNewOrder from '../../components/CheckNewOrder';

const DeliverymanList = ({
  setDeliverymen,
  setOpenOrderDistribute,
  selectOrder,
}) => {
  const [filter] = useState({
    type: "distribute",
  });
  const [page, setPage] = useState(1);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const perPage = 20;
  const { data, total, isLoading } = useGetList("deliveryman", {
    filter,
    pagination: { page, perPage },
    sort: { field: "id", order: "ASC" },
  });

  useEffect(() => {
    if (setDeliverymen) {
      setDeliverymen(data);
    }
  }, [data, setDeliverymen]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <Box className="deliveryman-list">
      <Card className="list">
        <DeliverymanTable
          deliverymanList={data}
          setDeliverymen={setDeliverymen}
          selectOrder={selectOrder}
          setOpenOrderDistribute={setOpenOrderDistribute}
        />
      </Card>

      <Toolbar>
        <Stack spacing={2}>
          <CustomPagination
            total={total}
            rowsPerPage={perPage}
            page={page}
            handleChangePage={handleChangePage}
          />
        </Stack>
      </Toolbar>
    </Box>
  );
};
export default DeliverymanList;
